<template>
  <div>
    <div class="px-12 py-7">
      <v-btn
        text
        small
        plain
        class="pa-0 mb-10"
        @click="goBackToProfile">
        <v-icon
          color="primaryColor"
          class="mr-4"
          >mdi-arrow-left</v-icon
        >
      </v-btn>

      <v-row
        align="center"
        no-gutters
        class="mb-12">
        <div class="d-flex mr-6">
          <IconWrap icon="history" />
        </div>
        <h1 class="page-title mr-12">History</h1>
        <v-btn
          depressed
          color="primaryColor"
          rounded
          class="filter-btn px-3 ml-12"
          @click="openCourseFiltersDialog">
          <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
          <span class="text-uppercase">Filters</span>
        </v-btn>
      </v-row>
      <!-- clear -->
      <v-row justify="end">
        <v-btn
          v-if="historyCourses.length"
          color="transparent"
          elevation="0"
          id="clear-history-button"
          class="text-capitalize"
          @click="clearHistory"
          :loading="isClearingHistory"
          >clear history</v-btn
        >
      </v-row>
      <!-- history -->
      <!-- loading -->
      <div
        v-if="isLoadingCourses"
        class="my-12">
        <v-progress-linear
          indeterminate
          color="primaryColor"></v-progress-linear>
      </div>
      <!-- courses -->
      <div
        class="courses-grid-wrap"
        v-else>
        <CourseRowCard
          v-for="course in filteredCourses"
          :key="course.id"
          :course="course" />
        <div
          class="mt-14 text-center"
          v-if="!historyCourses.length">
          No History found...
        </div>

        <course-filters-dialog
          :isDialogShown="showCourseFiltersDialog"
          :mediaType="mediaType"
          :maxTime="maxTime"
          @closeDialog="closeCourseFiltersDialog"
          @setMediaType="setMediaType"
          @setMaxTime="setMaxTime"
          @filterCourses="filterCourses(historyCourses)"
          @clearAllFilters="resetCourses(historyCourses)" />
      </div>
    </div>
  </div>
</template>

<script>
  import HistoryMixin from '@/views/profile/history/History.mixin.vue';
  import IconWrap from '@/components/general/icon-wrap/IconWrap.desktop.vue';
  export default {
    mixins: [HistoryMixin],
    components: {
      IconWrap,
    },
  };
</script>

<style lang="scss" scoped>
  .page-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
  }
  #clear-history-button {
    color: var(--v-errorDark-base);
    font-size: 0.8rem;
  }

  .filter-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    height: unset !important;
  }
  .v-image {
    filter: brightness(0) invert(1);
  }
  .courses-grid-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fit, 278px);
    gap: 16px;
  }
</style>
