<script>
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import CourseRowCard from '@/components/courses/course-row-card/CourseRowCard.vue';
  import { userFSDocumentMixin, courseFiltersMixin } from '@/mixins';
  import CourseFiltersDialog from '@/components/courses/course-filters-dialog/course-filters-dialog/CourseFiltersDialog.vue';

  export default {
    mixins: [userFSDocumentMixin, courseFiltersMixin],
    name: 'history',
    data() {
      return {
        isClearingHistory: false,
        isLoadingCourses: false,
      };
    },
    components: {
      CurvedHeader,
      CourseRowCard,
      CourseFiltersDialog,
    },
    watch: {
      async historyCourses() {
        this.setFilteredCourses(this.historyCourses);
      },
    },
    computed: {
      ...mapGetters('CoursesModule', ['courses', 'historyCourses']),
    },
    async created() {
      await this.fetchUser();
      await this.fetchHistoryCourses();
      this.setFilteredCourses(this.historyCourses);
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage', 'setSuccessSnackbarMessage']),
      ...mapActions('CoursesModule', {
        fetchAllCourses: 'fetchAll',
        getHistoryCourses: 'getHistoryCourses',
        clearCoursesHistory: 'clearCoursesHistory',
      }), // I couldn't just use the "to" attribute, because it overrides my styling
      goBackToProfile() {
        this.$router.push({
          name: 'Profile',
        });
        // todo : dispatch selected tab as profile
      },
      async fetchHistoryCourses(showLoading = true) {
        try {
          if (showLoading) {
            this.isLoadingCourses = true;
          }

          await this.getHistoryCourses();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoadingCourses = false;
        }
      },
      async clearHistory() {
        this.isClearingHistory = true;
        try {
          await this.clearCoursesHistory();
          await this.fetchHistoryCourses();
          this.setSuccessSnackbarMessage('History cleared successfully');
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isClearingHistory = false;
        }
      },
    },
  };
</script>
